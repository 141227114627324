<template>
	<div>
		<slide-show :bannerList="bannerList" :bannerHeight="152"></slide-show>
		<div class="searchBar">
			<Search
				:keys="getListParams.key"
				@searchKeyFun="searchKeyFun"
			></Search>
		</div>
		<template>
			<div class="main_Div contBox">
				<photoClassifyMenu
					:keys="getListParams.key"
					@changeMenu="changeMenu"
					@selectOrder="selectOrder"
					@selectAlbumFun="selectAlbumFun"
					@searchKeyFun="searchKeyFun"
					@changeData="changeData"
				></photoClassifyMenu>
				
				<div class="ListBox">
					<PhotoList
						ref="list"
						:colNum = "activeTab"
						:photoListObj="photoListObj"
						:loading="loading"
						:listStatus="listStatus"
						@nextPage="nextPage"
					></PhotoList>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import SlideShow from "./componts/SlideShow.vue";
import Search from "./componts/Search.vue";
import photoClassifyMenu from "./componts/photoClassifyMenu.vue";
import PhotoList from "./componts/PhotoList.vue";
// import { getBanner } from "../../Api";
import { mapGetters } from "vuex";
import { getToken } from "../../utils/auth";

export default {
	name: "CourseAlbum",
	components: {
		SlideShow,
		Search,
		photoClassifyMenu,
		PhotoList,
	},
	data() {
		return {
			bannerList: [{
				pic_str: require("../../assets/image/chartlet/chartletBg.png"),
				url: ""
			},],
			loading: false,
			listStatus: {
				isData: true,	// 判断是否有下一页
				isNull: false	// 判断是否为空
			},
			activeTab: 1,
			getListParams: {
				id: 2381,
				child: null,
				free: 0,
				page: 0,
				px: 1,
				reCom: 0,
				videoType: 0,
				key: "",
				dataTime: null,
				comeDownBanner: "",
			},
			
		};
	},
	created() {
    if (this.$route.query.album) {
			let album = parseInt(this.$route.query.album) || 0;
			if(album == 1) {
				this.activeTab = 2
			} else {
				this.activeTab = 1
			}
    }
	},
	methods: {
		//修改请求数据
		changeData(data) {
			this.getListParams.page = 1;
			this.getListParams = data;
		},
		//选择分类
		changeMenu(data) {
      this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.id = data;
		},
		//  选择排序
		selectOrder(data) {
      this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.order = data;
		},
		// 选择呈现形式
		selectAlbumFun(data) {
			this.$refs.list.clear();
			this.getListParams.page = 1;
			this.getListParams.is_album = data;
			if(data == 1) {
				this.activeTab = 2
			} else {
				this.activeTab = 1
			}
		},
		// 下一页
		nextPage() {
			this.getListParams.page += 1;
		},
		//  搜索
		searchKeyFun(keys) {
			this.$refs.list.clear();
			if ( this.getListParams.key == keys && this.getListParams.page == 1) {
				console.log(getToken());
				this.getList()
			} else {
				this.getListParams.page = 1;
				this.getListParams.key = keys;
			}
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.listStatus.isNull = false;
			this.listStatus.isData = true;
			if ( this.getListParams.page == 1 ) {
				this.$refs.list&&this.$refs.list.clear();
			}
			let params = this.$dealObjectValue(this.getListParams);
			this.$store
				.dispatch("app/getPhoto", params)
				.then((res) => {
					this.loading = false;
					const { data } = res;
					if (data.total == 0) {
						this.$refs.list.clear();
						this.listStatus.isNull = true;
					} else if (data.current_page == data.last_page) {
						this.listStatus.isData = false;
					}
				});
		},
	},
	computed: {
		...mapGetters(["photoListObj"]),
	},

	watch: {
		getListParams: {
			handler: function() {
				this.loading = true;
				(this.listStatus.isData = true), (this.listStatus.isNull = false);
				let params = this.$dealObjectValue(this.getListParams);
				this.$store.dispatch("app/getPhoto", params).then((res) => {
					this.loading = false;
					const {
						data
					} = res;
					if (data.total == 0) {
						this.$refs.list.imgList = [];
						this.listStatus.isNull = true;
					} else if (data.current_page == data.last_page) {
						this.listStatus.isData = false;
					}
				});
			},
			deep: true,
		},
	},
};
</script>
<style scoped>
::v-deep .is-animated {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.pageBox {
	width: 100%;
	text-align: center;
}

.searchBar {
	position: absolute;
	width: 100%;
	min-width: 1400px;
	top: calc(52px + 0.68rem);
	z-index: 8;
}

.contBox {
	position: relative;
	/* top: -0.66rem; */
	z-index: 8;
}
</style>
